<template>
  <div :style="getElementStyle">
    <el-row align="middle" class="Color">
      <span
        v-if="isActive || isDataTableField"
        class="setting-icon"
        @click="openSettings"
      >
        <i class="el-icon-s-tools" />
      </span>
      <el-col
         v-if="!isInDataTable" :span="showLabel && !isDefalutPos ? 8 : data.description ? 20 : 24"
      >
      <span style="display: flex;">
      <icons v-if="isIconRequired" :iconName="data.icon_name" class="mr-1"></icons>
        <label for="text" :style="getLabelStyles">{{ data.label }} </label>
      <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
        class="el-icon-copy-document " v-if="!isDataTableField" @click="copyDocument" ></i>
      </span>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col :span="showLabel && isDefalutPos ? 4 : 0">
        <!-- <i class="el-icon-info" :title="data.description"></i> -->
      </el-col>
      <el-col :span="showLabel && !isDefalutPos ? 12 : 24">
        <div>
          <el-radio-group v-model="localValue" class="Eradio">
            <ol :class="data.view_type === true ? 'verticalView' : 'horizontalView'">
              <li
                v-for="(option, index) of options"
                :key="index"
                :class="
                  data.view_type === true
                    ? 'radio-option'
                    : 'horizontalView radio-option'
                "
              >
                <el-radio :value="option" :label="option" border>
                  {{ option }}</el-radio
                >
              </li>
            </ol>
          </el-radio-group>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["data", "value", "isActive", "fieldsData", "isDataTableField","isInDataTable","isIconRequired"],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    getStyle() {
      return `height:${this.data.height - 30}px`;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}`
        : "";
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      isDefalutPos: true,
      showLabel: true,
      localValue: this.data.default_value,
    };
  },
  mounted() {
    this.options = this.data.options || [];
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      if (this.data.styles.labelStyle == "right") {
        this.isDefalutPos = false;
      }
    }
    console.log(this.data,"data......")
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    openSettings() {
      this.$emit("settings");
    },
    changeField() {
      this.$set(this.form, this.data.key, !this.form[this.data.key]);
    },
  },
  watch: {
    "data.options": {
      handler() {
        this.options = this.data.options || [];
      },
    },
    "data.default_value": {
      immediate: true, // Trigger the watcher immediately on component creation
      handler(newDefaultValue) {
        this.localValue = newDefaultValue; // Update localValue when default_value changes
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.radio-option {
  margin-bottom: 10px; /* Adjust the space between options at the bottom */
}
.horizontalView {
  display: inline-block;
  margin-right: 10px;
  padding: 0px;
}
.label-size {
  width: 100% !important;
}
.Rheight {
  height: 74px !important;
}
/*.label-size.label-size {
  background: pink;
} */
</style>
<style scoped>
.verticalView {
  position: relative;
  right: 25px !important;
}
</style>